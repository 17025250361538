<template>
  <div class="flex flex-wrap w-full min-h-screen">
    <div class="grid grid-cols-2 w-full">
      <div class="flex flex-col items-center justify-center gap-2 w-full">
        <div class="flex flex-col gap-2" style="width: 60%">
          <h3 class="text-2xl text-center mt-4">Tell us about you</h3>
          <div class="flex flex-row gap-2 items-center justify-center">
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="First Name" />
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Last Name" />
          </div>
          <div class="field">
            <AutoComplete class="p-fluid w-full" v-model="country" field="country" :suggestions="filtered" placeholder="Country" @complete="searchCountry($event)" />
            <span class="block text-red-500 italic text-left">{{ errors.first('password') }}</span>
          </div>
          <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Email Address" />
          <div class="flex flex-row gap-2 items-center justify-center">
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Password" />
            <InputText class="w-full" type="text" name="email" v-model="email" v-validate="'required|email'" placeholder="Password Confirm" />
          </div>
          <div class="p-field-checkbox text-sm">
            <Checkbox id="terms" v-model="consent" :binary="true" />
            <label for="terms" class="text-gray-900 dark:text-gray-300">
              I agree to SmeSpot's <span class="text-blue-500 cursor-pointer" @click="$emit('tos')">terms of service</span> and
              <span @click="$emit('privacy')" class="text-blue-500 cursor-pointer">privacy policy</span>
            </label>
          </div>
          <Button label="Create Account" class="w-full p-button" />
          <Divider />
          <Button class="p-button p-button-link" label="Back" />
        </div>
      </div>
      <div class="flex flex-col items-center justify-center border-l border-black">
        <img alt="sign-up" src="@/assets/images/signup-2.jpg" style="width: 80%" />
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import countries from '@/plugins/country-regions';
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import Divider from 'primevue/divider';

export default {
  components: {
    Divider,
    InputText,
    AutoComplete,
    Checkbox,
    Button,
  },
  data() {
    return {
      name: '',
      country: null,
      consent: false,
      consult: true,
      countries: countries,
      filtered: [],
      options: [
        { label: 'Find a Resource', value: false },
        { label: 'Provide a Service', value: true },
      ],
    };
  },
  methods: {
    searchCountry(event) {
      if (!event.query.trim().length) {
        this.filtered = [...this.countries];
      } else {
        this.filtered = this.countries.filter((c) => {
          return c.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
      }
    },
  },
};
</script>
